import React, { useEffect, useState } from "react";
import { ArrowRight, ArrowLeft } from "react-bootstrap-icons";
import "./index.scss";
const Pagination = (props) => {
    const { page, range, slicedData, changePage, recordsPerPage, changeRecordsPerpage } = props;
    return (
        <div className="d-flex gap-2">
            <RenderContent activePage={page} range={range} changePage={changePage} recordsPerPage={recordsPerPage} changeRecordsPerpage={changeRecordsPerpage} />
        </div>
    )
}
export default Pagination;
const RenderContent = ({ activePage, range, changePage, recordsPerPage, changeRecordsPerpage }) => {
    let startElement = range[0];
    let lastelement = range[range.length - 1];
    let [visibleSlots, setVisibleSlots] = useState([]);
    let slotOperations = [-2, -1, 0, 1, 2];
    useEffect(() => {
        if (activePage - 2 > startElement && activePage + 2 < lastelement) {
            setVisibleSlots(slotOperations.map(slotOperation => {
                return activePage + slotOperation
            }))
        }
        else if (activePage - 2 === startElement) {
            setVisibleSlots(slotOperations.map(slotOperation => {
                return slotOperation + activePage
            }))
        }
        else if (activePage - 1 === startElement) {
            let slotsCopy = slotOperations;
            let resultPositive = slotsCopy.splice(slotsCopy.indexOf(-1));
            slotsCopy.forEach(slotCopy => {
                resultPositive.push(resultPositive[resultPositive.length - 1] + 1);
            });
            setVisibleSlots(resultPositive.map(slot => slot + activePage))
        }
        else if (activePage === startElement) {
            let slotsCopy = slotOperations;
            let splicedCopy = slotsCopy.splice(slotsCopy.indexOf(0));
            for (let i = 1; i <= slotsCopy.length; i++) {
                splicedCopy.push(splicedCopy[splicedCopy.length - 1] + 1)
            }
            setVisibleSlots(splicedCopy.map(slot => slot + activePage));
        }
        else if (activePage + 2 === lastelement) {
            setVisibleSlots(slotOperations.map(slot => {
                return slot + activePage
            }))
        }
        else if (activePage + 1 === lastelement) {
            let slotsCopy = slotOperations;
            let reversedCopy = slotsCopy.reverse();
            let splicedVersion = reversedCopy.splice(1);
            reversedCopy.forEach(slot => splicedVersion.push(splicedVersion[splicedVersion.length - 1] - 1));
            setVisibleSlots(splicedVersion.map((splicedValueSet, index) => splicedValueSet + activePage).reverse())
        }
        else if (activePage === lastelement) {
            let slotsCopy = slotOperations;
            let resultPositive = slotsCopy.splice(slotsCopy.indexOf(0));
            slotsCopy.forEach(slotCopy => {
                resultPositive.push(resultPositive[resultPositive.length - 1] + 1)
            })
            setVisibleSlots(resultPositive.map(slot => activePage - slot).reverse())
        }
    }, [activePage, range]);

    return (
        <div className="d-flex justify-content-between align-items-center w-100 pagination">
            <div className="d-flex gap-2 align-items-center w-50">
                <div className="start-btn-group d-flex align-items-center gap-2">
                    <button className="first-page text-light" disabled={activePage === startElement || !range ? true : false} onClick={(e) => changePage(e, startElement)}>First</button>
                    <button onClick={(e) => changePage(e, activePage - 1)} disabled={activePage === startElement ? true : false} className="previous-page p-2 rounded-circle">
                        <ArrowLeft className="fw-bold fs-4" color="#f5aa33" />
                    </button>
                </div>
                {range.length > 5 ? <>
                    {
                        (activePage - 2) > startElement &&
                        <div className="dot-slots">
                            <span className="pagination-dots">.</span><span className="pagination-dots">.</span><span className="pagination-dots">.</span>
                        </div>
                    }
                    <div className="number-slots d-flex align-items-center gap-2">
                        {visibleSlots.length > 0 && visibleSlots.map((visibleSlot, index) => <span className={visibleSlot === activePage ? "pagination-links active-page" : "pagination-links in-active-page"} onClick={(e) => changePage(e, visibleSlot)} key={index}>{visibleSlot}</span>)}
                    </div>
                    {
                        (activePage + 2) < lastelement &&
                        <div className="dot-slots">
                            <span className="pagination-dots">.</span><span className="pagination-dots">.</span><span className="pagination-dots">.</span>
                        </div>
                    }
                </> : <div className="number-slots d-flex align-items-center gap-2">
                    {range.length > 0 && range.map((visibleSlot, index) => <span className={visibleSlot === activePage ? "pagination-links active-page" : "pagination-links in-active-page"} onClick={(e) => changePage(e, visibleSlot)} key={index}>{visibleSlot}</span>)}
                </div>}
                <div className="last-btn-group d-flex align-items-center gap-2">
                    <button disabled={activePage === range.length || !range ? true : false} className="next-page p-2 rounded-circle" onClick={(e) => changePage(e, activePage + 1)}><ArrowRight color="#f5aa33" className="fs-4 fw-bold" /></button>
                    <button className="last-page text-light" disabled={activePage === lastelement ? true : false} onClick={(e) => changePage(e, lastelement)}>Last</button>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end gap-4 w-50">
                <div className="page-list dropdown">
                    <button className="dropdown-toggle p-2 fs-4 rounded-2" style={{ color: "#f5aa33" }} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownpages">{activePage}</button>
                    <div className="dropdown-menu" style={{ height: "200px", overflow: "auto" }} aria-labelledby="dropdownpages">
                        {range.map((range, index) => <div onClick={(e) => changePage(e, range)} key={`key = ${index + range}`} style={{ color: activePage === range ? "#f5aa33" : "" }} className="dropdown-item">
                            {range}
                        </div>)}
                    </div>
                </div>
                <div className="dropdown page-range">
                    <button className="p-2 rounded-2 dropdown-toggle" style={{ color: "#f5aa33" }} data-bs-toggle="dropdown" id="dropdownrecordcount" aria-haspopup="true" aria-expanded="false">
                        <span className="text-muted fs-5">Records/Page</span> -
                        {recordsPerPage.defaultValue}
                    </button>
                    <div className="dropdown-menu p-2" aria-labelledby="dropdownrecordcount" style={{ position: "absolute", top: "65px", width: "100%", zIndex: 5, cursor: "pointer" }}>
                        {recordsPerPage.list.map((count, index) => <div className="dropdown-item" onClick={(e) => changeRecordsPerpage(e, count)} key={index} style={{ color: recordsPerPage.defaultValue === count ? "#f5aa33" : "" }}>
                            {count}
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    )
}