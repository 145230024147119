import React, { useEffect, useRef, useState, useLayoutEffect, useCallback } from "react";
import axios from "../../Hook/utils/constants/server";
import { useSearchParams, useLocation, useNavigate, createSearchParams, Routes, Route } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import TableList from "../../Hook/list";
import Countries from "../Data/countries/countries.json";
import useTable from "../../Hook/useTable";
import Pagination from "../../Hook/list/listfooter";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import Calendar from "react-calendar";
import "../../../src/Hook/CustomHook.css";
import useSearchParamsState from "../../Hook/useSearchparamsstate";
import "./index.scss";
import StoreDetail from "./storedetail";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
let pageValue;
let countries = [{ name: "All", flag: "" }].concat(Countries.map(country => ({ flag: country.code.toLowerCase(), name: country.name })));
const ReportsScreen = () => {
    const location = useLocation();
    const [searchParamsState, setSearchParamsState] = useSearchParamsState();
    const [page, setPage] = useState(searchParamsState.get("pageNumber") ? Number(searchParamsState.get("pageNumber")) : 1);
    const inputSearch = useRef(null);
    const searchCountry = useRef(null);
    const calenderDate = useRef(null);
    const navigation = useNavigate();
    const [filters, setFilters] = useState({
        loader: false,
        list: [],
        listCount: [],
        defaultPage: searchParamsState.get("pageNumber") ? searchParamsState.get("pageNumber") : 1,
        status: {
            default: searchParamsState.get("status") ? searchParamsState.get("status") : "all",
            list: ["all", "Active", "inActive"],
            triger: false
        },
        store_type: {
            default: searchParamsState.get("store_type") ? searchParamsState.get("store_type") : "all",
            list: ["all", "live", "testing"],
            triger: false
        },
        setup: {
            default: searchParamsState.get("setup_completed") ? searchParamsState.get("setup_completed") : "both",
            list: ["both", "true", "false"],
            triger: false
        },
        recordsPerpagedropdown: {
            defaultValue: searchParamsState.get("frequency_count") ? searchParamsState.get("frequency_count") : 25,
            list: [25, 50, 75, 100],
            menuOpen: false,
        },
        dates: {
            date: searchParamsState.get("start_date") && searchParamsState.get("end_date") ? [`${searchParamsState.get("start_date")}`, `${searchParamsState.get("end_date")}`] : [``, ``],
            showCalendar: false,
            triger: false
        },
        countries: {

            list: countries,
            selectedCountry: {
                flag: searchParamsState.get("country") ? `${countries.find(country => country.name === searchParamsState.get("country")).flag}` : "",
                name: searchParamsState.get("country") ? searchParamsState.get("country") : "All"
            },
            triger: false
        },
        planname: {
            default: { label: searchParamsState.get("plan") ? searchParamsState.get("plan") : "All", value: "all" },
            list: [{
                label: "All",
                value: "All"
            }, { label: "Basic", value: "basic" }, { label: "Premium", value: "premium" }, { label: "Free", value: "free" }],
            triger: false
        }, search: {
            value: searchParamsState.get("search") ? searchParamsState.get("search") : "",
            enabled: searchParamsState.get("search") ? true : false
        }
    });
    const [tableList, setTableList] = useState({
        thead: [
            {
                type: "text",
                title: "Serial No.",
                key: "serial"
            },
            {
                type: "text",
                title: "App Install Id",
                key: "app_install_id"
            },
            {
                type: "text",
                title: "Store Name",
                key: "store_name"
            },
            {
                type: "text",
                title: "Active Plan",
                key: "active_plan_name"
            },
            {
                type: "text",
                title: "Country",
                key: "country"
            },
            {
                type: "text",
                title: "Date",
                key: "date"
            },
            {
                type: "text",
                title: "SetUp Completed",
                key: "setup_completed"
            },
            {
                type: "text",
                title: "Shop Email",
                key: "shop_email"
            },
            {
                type: "text",
                title: "Status",
                key: "status"
            }, {
                type: "action",
                title: "Action",
                element: [{
                    type: "view", icon: <FaEye color="#f5aa33" />, action: (e, id) => {
                        positive(e, id);
                    }
                }]
            }
        ],
        tbody: [],
        tbodycopy: []
    });
    const handleInputSearch = e => {
        // if (e.key === "Backspace" && e.target.value === "") {
        //     setTableList({ ...tableList, tbody: tableList.tbodycopy });
        // }
        // else if (e.key === "Backspace" && e.target.value !== "") {
        //     let tableData = tableList.tbodycopy;
        //     let filteredData = tableData.filter(data => {
        //         if (data.country.toLowerCase().includes(e.target.value.toLowerCase()) || data.active_plan_name.toLowerCase().includes(e.target.value.toLowerCase()) || data.country.toLowerCase() === e.target.value.toLowerCase() || data.active_plan_name.toLowerCase() === e.target.value.toLowerCase()) {
        //             return data;
        //         }
        //     });
        //     setTableList({ ...tableList, tbody: filteredData });
        // }
        // else if (e.key !== "Backspace" && e.target.value !== "") {
        //     let tableData = tableList.tbody;
        //     let filteredData = tableData.filter(data => {
        //         if (data.country.toLowerCase().includes(e.target.value.toLowerCase()) || data.active_plan_name.toLowerCase().includes(e.target.value.toLowerCase()) || data.country.toLowerCase() === e.target.value.toLowerCase() || data.active_plan_name.toLowerCase() === e.target.value.toLowerCase()) {
        //             // console.log(data);
        //             return data;
        //         }
        //     });
        //     setTableList({ ...tableList, tbody: filteredData });
        // }
        // console.log(e.target.value)
        // console.log(inputSearch.current, filters.search.enabled);
        // inputSearch.current.value = e.target.value;
        if (e.target.value === "") {
            // console.log("inside the empty case");
            setFilters({ ...filters, search: { ...filters.search, value: "" } })
            // setTableList({ ...tableList, tbody: tableList.tbodycopy });
        }
        else if (e.target.value === "") {
            setFilters({ ...filters, search: { ...filters.search, value: "" } })
        }
        else if (e.target.value !== "") {
            // console.log("babaji")
            setFilters({ ...filters, search: { ...filters.search, value: e.target.value, enabled: true } })
        }
    };
    const handleFilterSearch = async () => {
        // setFilters({ ...filters, search: { ...filters.search, enabled: true } });
        let statePairs = {};
        if (!filters.countries.triger && !filters.dates.triger && !filters.planname.triger && !filters.setup.triger && !filters.status.triger && !filters.store_type.triger && !filters.search.value !== "" && filters.search.enabled === false && (filters.search.enabled && filters.search.value === "")) {
            return;
        }
        else if (filters.countries.triger || filters.dates.triger || filters.planname.triger || filters.setup.triger || filters.status.triger || filters.store_type.triger || filters.search.value !== "" || filters.search.enabled) {
            // console.log(inputSearch.current.value)
            if (filters.countries.triger) {
                // console.log()
                statePairs["country"] = filters.countries.selectedCountry.name
            }
            if (filters.dates.triger) {
                statePairs["start_date"] = filters.dates.date[0]; statePairs["end_date"] = filters.dates.date[1]
            }
            if (filters.setup.triger) {
                statePairs["setup_completed"] = filters.setup.default
            }
            if (filters.planname.triger) {
                statePairs["plan"] = filters.planname.default.label
            }
            if (filters.status.triger) {
                statePairs["status"] = filters.status.default
            }
            if (filters.store_type.triger) {

                statePairs["store_type"] = filters.store_type.default
            }
            if (filters.search.value !== "") {
                statePairs["search"] = filters.search.value;
            }
            if (filters.search.value === "") {
                // let { search, ...rest } = statePairs;
                // statePairs = rest;
                statePairs["search"] = ""
            }
            // console.log(statePairs);
            setSearchParamsState({ ...statePairs });
            setFilters({
                ...filters, loader
                    : true
            })
            let response = await axios.get("/user/reports", {
                method: 'GET',
                params: {
                    // pageNumber: filters.defaultPage,
                    pageNumber: searchParamsState.get("pageNumber") ? searchParamsState.get("pageNumber") : 1,
                    recordsPerpage: filters.recordsPerpagedropdown.defaultValue,
                    status: filters.status.default,
                    setup_completed: filters.setup.default,
                    store_type: filters.store_type.default,
                    country_name: filters.countries.selectedCountry.name,
                    start_date: filters.dates.date[0],
                    end_date: filters.dates.date[1],
                    plan: filters.planname.default.label,
                    search: filters.search.value
                }
            });
            if (response.data.data) {
                console.log("page", response.data.data.maxPage)
                setSearchParamsState({ ...statePairs, pageNumber: response.data.data.maxPage })
                setFilters({ ...filters, search: { ...filters.search, enabled: true }, loader: false, listCount: response.data.data.recordCount });
                setTableList({ ...tableList, tbody: modifyData(response.data.data.list), tbodycopy: modifyData(response.data.data.list) })
            }
        }
    }
    const handleCountrySearch = e => {
        searchCountry.current.value = e.currentTarget.value;
        if (e.key === "Backspace" && searchCountry.current.value === "") {
            setFilters({
                ...filters, countries: {
                    ...filters.countries, list: countries
                }
            })
        }
        else if (e.key === "Backspace" && searchCountry.current.value !== "") {
            setFilters({
                ...filters, countries: {
                    ...filters.countries, list: Countries.filter(country => {
                        if (country.name.toLowerCase().includes(searchCountry.current.value.toLowerCase()) || country.name === searchCountry.current.value)
                            return country

                    }).map(country => ({
                        flag: country.code.toLowerCase(),
                        name: country.name
                    }))
                }
            })
        }
        else {
            setFilters({
                ...filters, countries: {
                    ...filters.countries, list: Countries.filter((country) => {
                        if (country.name.toLowerCase().includes(searchCountry.current.value.toLowerCase()) || country.name === searchCountry.current.value) {
                            return country
                        }
                    }
                    ).map(country => ({
                        flag: country.code.toLowerCase(),
                        name: country.name
                    }))
                }
            });
        }
    }
    const modifyData = data => {
        return data.map((data, index) => ({ serial: index + 1, ...data, date: `${new Date(data.date).getFullYear()} - ${new Date(data.date).getMonth() + 1}-${new Date(data.date).getDay()}` }))
    }
    const onPageChange = (e, pageNumber) => {
        setPage(pageNumber);
        pageValue = pageNumber;
        setSearchParamsState({ pageNumber: pageNumber })
    }
    const positive = (e, id) => {
        navigation({
            pathname: "/dashboard/reports/details",
            search: createSearchParams({ id }).toString()
        })
    }
    const handleDateChange = e => {
        let dateSets = e.map(date => `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`);
        setFilters({ ...filters, dates: { ...filters.dates, date: [...dateSets], showCalender: !filters.dates.showCalendar, triger: true } });
    }
    const changeRecordsPerpage = (e, count) => {
        setFilters({ ...filters, recordsPerpagedropdown: { ...filters.recordsPerpagedropdown, defaultValue: count } });
        setSearchParamsState({ frequency_count: count })
    }
    const { slicedData, tablerange } = useTable(filters.list, filters.listCount, page, filters.recordsPerpagedropdown.defaultValue);
    React.useEffect(() => {
        let list = [...searchParamsState.entries()];
        if (tablerange.length === 0) {
            if (searchParamsState.get("pageNumber")) {
                pageValue = Number(searchParamsState.get("pageNumber"));
            }
            else {
                pageValue = 1;
            }
        }
        else if (tablerange.length > 0) {

            if (searchParamsState.get("pageNumber")) {
                if (tablerange[tablerange.length - 1] < searchParamsState.get("pageNumber")) {
                    // console.log("check")
                    pageValue = Number(tablerange[tablerange.length - 1]);
                    setSearchParamsState({ pageNumber: pageValue });
                }
                else if (tablerange[tablerange.length - 1] > searchParamsState.get("pageNumber")) {
                    // console.log("second")
                    pageValue = Number(searchParamsState.get("pageNumber"));
                    setSearchParamsState({ pageNumber: pageValue })
                }
                else if (tablerange[tablerange.length - 1] == (searchParamsState.get("pageNumber"))) {
                    // console.log("Positive")
                    pageValue = Number(searchParamsState.get("pageNumber"));
                    setSearchParamsState({ pageNumber: pageValue })
                }
            }
            else {
                pageValue = 1;
                setSearchParamsState({ pageNumber: pageValue })
            }
        }
        if (list.length === 1 && list[0][0].includes("pageNumber") || list.length === 0) {
            setFilters((filters) => ({ ...filters, loader: true }));
            axios.get("/user/reports", {
                method: "GET",
                params: {
                    recordsPerpage: filters.recordsPerpagedropdown.defaultValue,
                    pageNumber: pageValue,
                    status: searchParamsState.get("status"),
                    store_type: searchParamsState.get("store_type"),
                    country_name: searchParamsState.get("country"),
                    setup_completed: searchParamsState.get("setup_completed"),
                    start_date: searchParamsState.get("start_date"),
                    end_date: searchParamsState.get("end_date"),
                    plan: searchParamsState.get("plan"),
                    search: searchParamsState.get("search")
                }
            }).then(response => {
                setSearchParamsState({ pageNumber: response.data.data.maxPage })
                setFilters({ ...filters, listCount: response.data.data.recordCount, loader: false });
                setTableList({ ...tableList, tbody: response.data.data.list, tbodycopy: modifyData(response.data.data.list) });
            }).catch(er => {
                console.log("Error", er)
                throw new Error(er)
            })
        }
        else {
            // console.log("Poisitivevhvghfb")
            axios.get("/user/reports", {
                method: 'GET',
                params: {
                    pageNumber: pageValue,
                    recordsPerpage: filters.recordsPerpagedropdown.defaultValue,
                    status: searchParamsState.get("status"),
                    setup_completed: searchParamsState.get("setup_completed") ? searchParamsState.get("setup_completed") : null,
                    store_type: searchParamsState.get("store_type") ? searchParamsState.get("store_type") : null,
                    country_name: searchParamsState.get("country") ? searchParamsState.get("country") : null,
                    start_date: searchParamsState.get("start_date") ? searchParamsState.get("start_date") : null,
                    end_date: searchParamsState.get("end_date") ? searchParamsState.get("end_date") : null,
                    plan: searchParamsState.get("plan") ? searchParamsState.get("plan") : null,
                    search: searchParamsState.get("search")
                }
            }).then(response => {
                setSearchParamsState({ pageNumber: response.data.data.maxPage })
                setFilters({ ...filters, listCount: response.data.data.recordCount });
                setTableList({ ...tableList, tbody: modifyData(response.data.data.list), tbodycopy: modifyData(response.data.data.list) });
            }).finally(positive => positive);
        }
    }, [pageValue, filters.recordsPerpagedropdown.defaultValue]);
    // useEffect(() => {
    //     console.log("SearchPositive", searchParamsState.get("pageNumber"), "MaxRange", tablerange[tablerange.length - 1])
    // }, [searchParamsState.get("pageNumber")])
    return (
        <>
            <div className="container grid-box main-container">
                <div className=" mb-0 border border-white p-2 d-flex align-items-center justify-content-between">
                    <div>
                        <h1 style={{ color: "#f5aa33" }}>
                            Reports
                        </h1>
                    </div>
                    <div className="search-country w-75">
                        <input className="search-input w-100" defaultValue={filters.search.value} onChange={handleInputSearch} placeholder="Search by shop name or shop url or store name or store owner" />
                    </div>
                </div>
                <div className="p-2 row">
                    <div className="d-flex align-items-end justify-content-end gap-2">
                        <div className="countries-filter filter-country dropdown d-flex flex-column">
                            <span style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>Sort By Country</span>
                            <button className="dropdown-toggle p-2 d-flex gap-5 rounded-2 d-flex align-items-center justify-content-between" id="dropdowncountry" aria-haspopup="true" style={{ color: "#f5aa33" }} aria-expanded="false" data-bs-toggle="dropdown" >
                                <p className="mb-0 gap-2 d-flex align-items-center" style={{ color: "#f5aa33" }}>
                                    <span>
                                        {filters.countries.selectedCountry.flag !== "" &&
                                            <img className="" style={{ width: "25px", height: "25px" }} src={`https://flagcdn.com/${filters.countries.selectedCountry.flag}.svg`} />
                                        }
                                    </span>
                                    <span className="">
                                        {filters.countries.selectedCountry.name}
                                    </span>
                                </p>
                            </button>
                            <div className="dropdown-menu country-dropdown p-2 position-absolute" aria-labelledby="dropdowncountry" >
                                <div className="seacrh-input w-100 position-sticky top-0">
                                    <input className="w-100 p-2 search-input" onKeyUp={handleCountrySearch} ref={searchCountry} type="text" placeholder="Search by country" />
                                </div>
                                {filters.countries.list.length > 0 && filters.countries.list.map((country, index) => (
                                    <div className="dropdown-item p-1 d-flex align-items-center gap-2" key={index} onClick={() => {
                                        setFilters({ ...filters, countries: { ...filters.countries, selectedCountry: { ...filters.countries.selectedCountry, name: country.name, flag: country.flag }, triger: true } });
                                    }

                                    } style={{ cursor: "pointer" }}>
                                        <span className="img-container">
                                            {country.flag !== "" &&
                                                <img className="" style={{ width: "25px", height: "25px" }} src={`https://flagcdn.com/${country.flag}.svg`} />
                                            }
                                        </span>
                                        <span className="text-muted">
                                            {country.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="setup-filter filter-setup dropdown d-flex flex-column">
                            <span className="fs-6" style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>
                                SetUp
                            </span>
                            <button style={{ color: "#f5aa33" }} className="dropdown-toggle p-2 rounded-2 d-flex align-items-center gap-5 justify-content-between" id="dropdownsetup" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.setup.default === "true" && "Completed"}
                                {filters.setup.default === "both" && "Both"}
                                {filters.setup.default === "false" && "Not completed"}
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="dropdownsetup">
                                {filters.setup.list.map((setup_completed, index) => (
                                    <div className="dropdown-item" key={setup_completed} style={{ color: filters.setup.default === setup_completed ? "#f5aa33" : "", cursor: "pointer" }} onClick={() => {
                                        setFilters({ ...filters, setup: { ...filters.setup, triger: true, default: setup_completed } });
                                    }
                                    }>
                                        {setup_completed === "true" && "Completed"}
                                        {setup_completed === "both" && "Both"}
                                        {setup_completed === "false" && "Not Completed"}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="status-filter filter dropdown d-flex flex-column">
                            <span className="fs-6" style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>Status</span>
                            <button style={{ color: "#f5aa33" }} className="dropdown-toggle p-2 rounded-2 d-flex gap-5 align-items-center justify-content-between" id="dropdownstatus" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.status.default === "all" && "All"}
                                {filters.status.default === "Active" && "Active"}
                                {filters.status.default === "inActive" && "In Active"}
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="dropdownstatus">
                                {filters.status.list.map((status, index) => <div style={{ color: filters.status.default === status ? "#f5aa33" : "", cursor: "pointer" }} className="dropdown-item" key={index} onClick={() => {
                                    setFilters({ ...filters, status: { ...filters.status, default: status, triger: true } });
                                }}>
                                    {status === "all" && "All"}
                                    {status === "inActive" && "InActive"}
                                    {status === "Active" && "Active"}
                                </div>)}
                            </div>
                        </div>
                        <div className="store-type-filter filter dropdown d-flex flex-column">
                            <span className="fs-6" style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>Store</span>
                            <button className="dropdown-toggle p-2 rounded-2 d-flex gap-5 align-items-center justify-content-between" style={{ color: "#f5aa33" }} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownstoretype">
                                {filters.store_type.default === "live" && "Live"}
                                {filters.store_type.default === "all" && "All"}
                                {filters.store_type.default === "testing" && "Testing"}
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="dropdownstoretype">
                                {filters.store_type.list.map((store_type) => <div onClick={() => {
                                    setFilters({ ...filters, store_type: { ...filters.store_type, triger: true, default: store_type } });
                                }
                                } key={store_type} style={{ color: filters.store_type.default === store_type ? "#f5aa33" : "", cursor: "pointer" }} className="dropdown-item">
                                    {store_type === "live" && "Live"}
                                    {store_type === "all" && "All"}
                                    {store_type === "testing" && "Testing"}
                                </div>)}
                            </div>
                        </div>
                        <div className="plan-name-filter filter dropdown d-flex flex-column">
                            <span className="mb-0 fs-6" style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>Plan Name</span>
                            <button className="dropdown-toggle d-flex justify-content-between align-items-center p-2 rounded-2 gap-5" style={{ color: "#f5aa33" }} id="planname" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.planname.list.find(plan => plan.label === filters.planname.default.label).label}
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="planname">
                                {filters.planname.list.map((plan, index) => <div className="dropdown-item" style={{ color: plan.value === filters.planname.default.value ? "#f5aa33" : "", cursor: "pointer" }} key={index} onClick={() => {
                                    setFilters({ ...filters, planname: { ...filters.planname, default: { ...filters.planname.default, label: plan.label }, triger: true } });
                                }
                                }>{plan.label}</div>)}
                            </div>
                        </div>
                        <div className="date-wise  position-relative d-flex flex-column align-items-start">
                            <span style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }} className="fs-6">Dates</span>
                            <button className="p-2 rounded-2 dropdown d-flex align-items-start justify-content-between w-100" onClick={() => setFilters({ ...filters, dates: { ...filters.dates, showCalendar: !filters.dates.showCalendar } })}>
                                <div className="position-relative d-flex gap-1 align-items-center" >
                                    <span className="fs-6" >
                                        Start -
                                    </span>
                                    <span style={{ fontSize: "small" }}>{filters.dates.date[0]}</span>
                                </div>
                                <div className="position-relative d-flex gap-1 align-items-center ">
                                    <span className="fs-6" >End -</span>
                                    <span style={{ fontSize: "small" }}>{filters.dates.date[1]}</span>
                                </div>
                            </button>
                            {filters.dates.showCalendar && <div className="calender position-absolute" style={{ top: "85px", left: "-100px", zIndex: 10 }}>
                                <Calendar ref={calenderDate} selectRange onChange={handleDateChange} /></div>}
                        </div>
                        <div className="trigger-filters fs-5">
                            <OverlayTrigger placement={"bottom-end"} overlay={<Tooltip>{"Trigger Search"}</Tooltip>}>
                            <button onClick={() => handleFilterSearch()} className="button-filter text-light p-2 rounded-2" style={{ backgroundColor: "#f5aa33" }}>GO</button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 grid-box m-0">
                        <TableList tabledata={tableList} loading={filters.loader} />
                    </div>
                </div>
                {!filters.loader &&
                    <div className="row">
                        {
                            location.pathname.includes("reports") && tablerange.length > 0 &&
                            <div className="col-12 grid-box position-relative">
                                <Pagination recordsPerPage={filters.recordsPerpagedropdown} changeRecordsPerpage={changeRecordsPerpage} page={searchParamsState.get("pageNumber") ? Number(searchParamsState.get("pageNumber")) : 1} range={tablerange} slicedData={slicedData} changePage={onPageChange} />
                            </div>
                        }
                    </div>
                }
            </div>
        </>
    )
}
const Reports = () => {
    return (
        <Routes>
            <Route index element={<ReportsScreen />} />
            <Route path="/details" element={<StoreDetail />} />
        </Routes>
    )
}
export default Reports;