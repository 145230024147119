import React, { useEffect } from "react";
import "../../Components/ChartPage/Chartpage.css";
import { Table } from "react-bootstrap";
import Countries from "../../Components/Data/countries/countries.json";
import ReactCountryFlag from "react-country-flag";
import { Backdrop, Tooltip } from "@mui/material";
import Loading from "../Spinner.js";
import { Overlay, OverlayTrigger } from "react-bootstrap";
const Tablelist = (props) => {
    const { loading } = props;
    return (
        <>
            {loading ? <Backdrop open sx={{ color: "#f5aa33" }}><Loading /></Backdrop> : <>
                <table className="h-100 w-100">
                    <thead className="">
                        <tr>
                            {props.tabledata.thead.map((cell, index) => <th key={index} className="border border-light">
                                {cell.title}
                            </th>)}
                        </tr>
                    </thead>
                    {
                        props.tabledata.tbody.length > 0 && !loading ?
                            <>
                                <tbody>
                                    {props.tabledata.tbody.map((row, rowindex) => <tr key={rowindex}>
                                        {props.tabledata.thead.map((cell, cellindex) => <td key={cellindex}>

                                            {cell.type === "index" && rowindex + 1}
                                            {(cell.type === "thumbnail" && cell.type !== "text") &&
                                                <img className="" style={{ width: "45px", height: "45px" }} src={`https://flagcdn.com/${row[cell.image]}.svg`} />
                                            }
                                            {cell.type === "text" && (row[cell.key]) === "" ? "--" : row[cell.key]}
                                            {cell.type === "action" && <span style={{ cursor: "pointer" }}>{cell.element.map((type, index) => {
                                                if (type.type === "edit") {
                                                    return <span key={index} className="edit-icon" onClick={(e) => type.action(e, row["app_install_id"])}>{type.icon}
                                                    </span>
                                                } else if (type.type === "view") {
                                                    return <Tooltip title={type.type}> <i key={index} onClick={(e) => type.action(e, row["app_install_id"])}>{type.icon}</i></Tooltip>
                                                }

                                            })}</span>}
                                        </td>)}
                                    </tr>)}
                                </tbody>
                            </> :
                            <>
                                <tbody className="no-data text-center">
                                    <tr className="text-center">
                                        <td colSpan={10}>
                                            NO RECORD FOUND!
                                        </td>
                                    </tr>
                                </tbody>
                            </>
                    }

                </table>
            </>
            }
        </>

    )
}
export default Tablelist; 