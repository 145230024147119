import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import axios from '../../../Hook/utils/constants/server';
import useSearchParamsState from "../../../Hook/useSearchparamsstate";
import Backdrop from "@mui/material/Backdrop";
import Loading from "../../../Hook/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.scss";
import Tablelist from "../../../Hook/list";
import Card from "react-bootstrap/Card";
import { ArrowUp } from "react-bootstrap-icons";
import data from "../../Data/Chart/Bar.json";
import { Bar, Tooltip, BarChart } from "recharts";
import { Calendar } from "react-calendar";
import Countries from "../../Data/countries/countries.json";
const modifydate = date => {
    let dateWithouttime = new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });

    let dateWithtime = new Date(date).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    });
    return `${dateWithouttime} ${dateWithtime}`
}
const StoreDetail = () => {
    const [stateGroup, setStategroup] = useState({
        loader: false,
        storeDetail: {},
        store_logs: [],
        dates: {
            showCalender: false,
            dates: [null, null]
        }
    });
    const positive = useOutletContext();
    const [tableData, setTabledata] = useState({
        thead: [{
            type: "text",
            title: "App Install Id",
            key: "app_install_id"
        }, {
            type: "text",
            title: "Log Id",
            key: "log_id"
        }, {
            type: "text",
            title: "Plan Name",
            key: "plan_name"
        }, {
            type: "text",
            title: "Date",
            key: "log_date"
        }],
        tbody: []
    });
    const [analyticsWidget, setAnalyticswidget] = useState([
        {
            widgettype: "Wishlist Users",
            subheading: "Total number of wishlist users",
            dataSet: {
                count: 0
            }
        },
        {
            widgettype: "Wishlist Items",
            subheading: "Total number of wishlist items",
            dataSet: {
                count: 0
            }
        },
        {
            widgettype: 'Total Revenue',
            subheading: "Items added to the cart from the app is the total reveue",
            dataSet: {
                count: 0
            }
        },
        {
            widgettype: "Monthly Quota",
            subheading: "monthly quota is used",
            dataSet: {
                count: 0
            }
        }
    ]);
    const [searchParamsState, setSearchParamsState] = useSearchParamsState();
    const fetchStoreDetail = async (id) => {
        const response = await axios.get("/user/storedetails", {
            method: "GET",
            params: {
                app_install_id: id
            }
        });

        return response;
    }
    const modifyData = data => {
        let modifiedData = data.map((key, index) => ({
            app_install_id: key.app_install_id,
            plan_name: key.plan_name,
            log_date: `${new Date(key.log_date).getFullYear()}-${new Date(key.log_date).getMonth() + 1}-${new Date(key.log_date).getDate()} ${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()} ${new Date(key.log_date).getMilliseconds()}`,
            log_id: key.log_id
        }));
        return modifiedData;
    }
    const handleDateChange = date => {
        console.log(date);
    }
    useEffect(() => {
        setStategroup({ ...stateGroup, loader: true });
        fetchStoreDetail(searchParamsState.get("id") ? searchParamsState.get("id") : 1).then(response => {
            response && setStategroup({ ...stateGroup, loader: false, storeDetail: response.data.data[0], store_logs: response.data.store_logs });
            setTabledata({ ...tableData, tbody: modifyData(response.data.store_logs) });
            setAnalyticswidget([{ widgettype: "Wishlist Users", subheading: "Total number of wishlist users", dataSet: { count: response.data.wishlist_users } }, { widgettype: "Wishlist Items", subheading: "Total number of wishlist items", dataSet: { count: response.data.wishlist_items } }, { widgettype: "Total Income", subheading: "Items added to the cart from the app is the total revenue", dataSet: { count: response.data.totalRevenue } }, { widgettype: "Monthly Quota", subheading: "Monthly Quota is used", dataSet: { count: response.data.monthlyQuota } }])
        }).catch(er => {
            alert(`Something went wrong , please check the respective consoles`);
            if (er) throw new Error(er);
        });
    }, []);
    return (
        <>
            <Container fluid className="grid-box store-basic-details">
                <div className="header-label row p-2 grid-box-heading">
                    <h3 className="page-label">Store Detail</h3>
                </div>
                <Row className="grid-box-heading detail-section-container p-2">
                    <Col xs={12} className="bg-light">
                        <div className="p-2">
                            <h3 className="page-label">Basic Details</h3>
                        </div>
                        <div className="d-flex gap-2 justify-content-between w-100 p-2">
                            <span className="fs-4 side-labels">Store Name - {stateGroup.storeDetail.store_name}</span>
                            <span className="fs-4 side-labels">Active Plan - {stateGroup.storeDetail.active_plan_name}</span>
                        </div>
                        <div className="">
                            <GridList recordList={[...Object.entries(stateGroup.storeDetail)]} positive={stateGroup.loader} />
                        </div>
                    </Col>
                    <Col xs={12} className="p-2 bg-light">
                        <div className="p-2">
                            <h3>Installation Logs</h3>
                            <div className="log-list p-3 bg-white">
                                <Tablelist tabledata={tableData} />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} className="p-2 bg-light analytics-page container grid-box">
                        <Row className="UserListing-row p-0">
                            <div className="d-flex stats-label align-items-center justify-content-between">
                                <h3 className="page-label">
                                    Store Stats
                                </h3>
                                <div className="date-picker">
                                    <button style={{ color: "#f5aa33" }} className="p-2 bg-light" onClick={(e) => setStategroup({ ...stateGroup, dates: { ...stateGroup.dates, showCalender: !stateGroup.dates.showCalender } })}>
                                        Choose Date
                                    </button>
                                    {stateGroup.dates.showCalender && <Calendar className={"date-calender"} selectRange onChange={handleDateChange} />}
                                </div>
                            </div>
                            {analyticsWidget.length > 0 && analyticsWidget.map((widget, index) =>
                                <Col md={3} className="userListing-box bg-light px-1" key={index}>
                                    <div className="userListing-box bg-white">
                                        <h3>{widget.widgettype}</h3>
                                        <h2>{Math.ceil(widget.dataSet.count)}</h2>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default StoreDetail;
const GridList = (props) => {

    let { recordList, positive } = props;
    let firstSection = recordList.slice(0, Math.ceil(recordList.length / 2));
    let secondSection = recordList.slice(Math.ceil(recordList.length / 2));
    return (
        <>
            {positive ? <Backdrop open>
                <Loading />
            </Backdrop> : <Container fluid className="grid-box">
                <Row className="">
                    <Col xs={6} className="border border-0 border-end-1 border-muted p-1">
                        {firstSection.map(([key, value], index) => <div className="d-flex align-items-center gap-1 property-row" key={index}>
                            <label className="">{key}</label> -
                            <span>{value ? key === "log_date" ? modifydate(value) : value : "null"}</span>
                            
                        </div>)}
                    </Col>
                    <Col xs={6} className="border border-0 border-start-1 border-muted p-1">
                        {secondSection.map(([key, value], index) => <div className='d-flex align-items-center gap-1 property-row' key={index}>
                            <label>{key}</label> -
                            <span>{value ? key === "log_date" ? modifydate(value) : value : "null"}</span>
                        </div>)}
                    </Col>
                </Row>
            </Container>}
        </>
    )
}