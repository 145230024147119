import React from 'react';
import { FaEye } from "react-icons/fa";
import MetaTags from '../../HeadTag/MetaTags';
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FiEdit2, FiDelete } from "react-icons/fi";
import Tablelist from '../../../Hook/list';
import axios from '../../../Hook/utils/constants/server';
import { local } from '../../../Hook/utils/constants/server';
import { IoMdArrowDown } from "react-icons/io";
import { AiOutlineUserDelete } from "react-icons/ai";
import { RiArrowDropDownLine, RiInstallFill } from "react-icons/ri";
import {
    Tooltip,
    Bar, BarChart,
    PieChart,
    Pie,
    Cell,
    Legend,
    ResponsiveContainer,
    XAxis, YAxis, CartesianGrid
} from "recharts";
import RevenueData from "../../Data/Chart/RevenueDeviceData.json";
import PieCharts from '../../../Hook/Recharts/PieCharts';
import data from "../../Data/Chart/Bar.json";
import { ReactCountryFlag } from "react-country-flag";
import "../Chartpage.css";
import { Backdrop, Typography } from '@mui/material';
import countryList from "../../../Components/Data/countries/countries.json";
import { HiArrowNarrowUp, HiArrowNarrowDown } from "react-icons/hi";
import Loading from '../../../Hook/Spinner';
import { createSearchParams, useNavigate } from "react-router-dom";
import "./index.scss";
const Widgets = () => {
    let [users, setusers] = useState({
        activeInstalls: [],
        allInstalls: [],
        loading: false,
        toast: false,
        tabledata: {
            thead: [
                {
                    type: "text",
                    title: "App Install Id",
                    key: "app_install_id"
                },
                {
                    key: "date",
                    title: "Date",
                    type: "text"
                },
                {
                    key: "store_owner",
                    title: "Store Owner",
                    type: "text"
                },

                {
                    key: "active_plan_name",
                    title: "Plan",
                    type: "text"
                }, {
                    key: "country",
                    title: "Country",
                    type: "text"
                },
                {
                    key: "status",
                    title: "Status",
                    type: "text"
                },
                {
                    element: [{
                        type: "view", action: (e, store_id) => {
                            navigate({
                                pathname: "/dashboard/reports/details",
                                search: createSearchParams({ id: store_id }).toString()
                            })
                        }, icon: <FaEye color={"#f5aa33"} />
                    }],
                    title: "Action",
                    type: "action"
                },
            ],
            tbody: []
        }
    });
    const navigate = useNavigate();
    const [countryWiseInstalls, setCountryWiseInstalls] = React.useState({
        frequency: "all",
        tabledata: {
            thead: [{
                type: "text",
                title: "Install Count",
                key: "install_count"
            }, {
                type: "text",
                title: "Country",
                key: "country_name"
            }, {
                type: "text",
                title: "Store Type",
                key: "store_type"
            }, {
                type: "thumbnail",
                title: "Flag",
                image: "image"
            }],
            tbody: []
        }
    });
    const [barData, setBarData] = useState([]);
    let [monthlyRevenue, setMonthlyRevenue] = useState({
        currentMonthRevenue: {
            totalprice: 0,
            currentMonthName: ""
        },
        previousMonthRevenue: {
            totalprice: 0,
            previousMonthName: ""
        }
    });
    let [widgetretention, setWidgetRetention] = useState({
        currentMonthRetentionPercent: null,
        previousMonthRetentionPercent: null,
        currentMonthActiveRecords: null,
        currentMonthTotalRecords: null,
        previousMonthActiveRecords: null,
        previousMonthTotalRecords: null
    });
    let [revenueByDeviceData, setrevenueByDeviceData] = useState("week");
    const formatdata = data => {
        let modifiedData = [];
        const dateformater = date => {
            let datemodified = new Date(date);
            let day = datemodified.getDate();
            let month = datemodified.getMonth() + 1;
            let year = datemodified.getFullYear().toString().slice(-2);
            const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
            return formattedDate;
        }
        modifiedData = data.map((key, index) => {
            return {
                active_plan_name: key.active_plan_name,
                app_install_id: key.app_install_id,
                country: key.country,
                date: dateformater(key.date),
                shop_email: key.shop_email,
                shop_phone: key.shop_phone,
                status: key.status,
                store_owner: key.store_owner
            }
        })
        return modifiedData;
    }
    const [appwidget, setAppWidget] = useState([]);
    const [showdropdownfilter, setShowdropdownfilter] = useState({
        showdropdown: false,
        status: "all",
        frequency: "all"
    });
    const [charttabledata, setChartTableData] = useState({
        frequencyType: "week",
        thead: [{
            key: "name",
            title: "Plan Name",
            type: "text"
        }, {
            key: "percent",
            title: "Percent(%)",
            type: "text"
        }, {
            key: "value",
            title: "Value",
            type: "text"
        }],
        tbody: []
    });
    const setAll = () => setShowdropdownfilter({ ...showdropdownfilter, status: "all" });
    const setActive = () => setShowdropdownfilter({ ...showdropdownfilter, status: "Active" });
    const setInActive = () => setShowdropdownfilter({ ...showdropdownfilter, status: "inActive" });
    const fetchMonthlyRevenueLastMonth = frequency => {
        let date = new Date();
        let currentDate = date.getDate();
        let currentMonth = date.getMonth() + 1;
        let year = date.getFullYear();
        let previousMonthDate = `${year}-${currentMonth - 1}-${currentDate}`;
        let currentMonthDate = `${year}-${currentMonth}-${currentDate}`;
        axios.get("/user/getpaymentlogs", {
            params: {
                startdate: previousMonthDate,
                enddate: currentMonthDate
            }
        }).then(resonse => {
        }).catch(er => console.log("Errr", er));
    }
    const allActiveBarData = frequency => {
        let testValue = [];
        const data = frequency.map(item => ({
            name: item.currentDate,
            label: item.currentDate,
            installcount: item.count
        }));
        // data.forEach((dataitem, indexitem) => {
        //     testValue.push({
        //         name: dataitem.name,
        //         label: dataitem.label,
        //         install_count: dataitem.install_count
        //     });
        //     if (indexitem < data.length - 1) {
        //         let dateNumber = new Date(dataitem.name).getDate();
        //         // console.log("Dates",dateNumber);
        //         // dateNumber + 1
        //         if (new Date(data[indexitem + 1].name).getDate() !== (dateNumber + 1)) {
        //             for (let count = 1; count <= (new Date(data[indexitem + 1].name).getDate() - dateNumber - 1); count++) {
        //                 testValue.push({
        //                     name: dateNumber + 1,
        //                     label: dateNumber + 1,
        //                     install_count: 0
        //                 })
        //             }
        //         }
        //     }
        // });
        // console.log(data)
        // console.log("Testvalue", testValue);
        data.sort((a, b) => {
            let firstDate = Date.parse(a.name);
            let secondDate = Date.parse(b.name);
            return new Date(secondDate) - new Date(firstDate);
        });

        setBarData(data);
    }
    const fetchTimely = frequency => {
        setShowdropdownfilter({ ...showdropdownfilter, frequency })
    }
    const calculateWidgetRevenue = (positive, previousPositive, currentMonth, previousMonth) => {
        let basicRevenuePrice = 4.99;
        let freeRevenuePrice = 0;
        let premiumRevenuePrice = 14.99;
        let basicCurrentMonth = 0;
        let freeCurrentMonth = 0;
        let premiumCurrentMonth = 0;
        let basicPreviousMonth = 0;
        let freePreviousMonth = 0;
        let premiumPreviousMonth = 0;
        let currentMonthName = "";
        let previousMonthName = "";

        positive.forEach(positive => {
            if (positive.active_plan_name === "Basic") {
                basicCurrentMonth = basicCurrentMonth + (basicRevenuePrice * positive["count(app_install_id)"]);
            }
            else if (positive.active_plan_name === "No Plan") {
                freeCurrentMonth = freeCurrentMonth + (freeRevenuePrice * positive["count(app_install_id)"]);
            }
            else if (positive.active_plan_name === "Premium") {
                premiumCurrentMonth = premiumCurrentMonth + (premiumRevenuePrice * positive["count(app_install_id)"]);
            }
        });
        previousPositive.forEach(previousPositive => {
            if (previousPositive.active_plan_name === "Basic") {
                basicPreviousMonth = basicPreviousMonth + (basicRevenuePrice * previousPositive["count"]);
            }
            else if (previousPositive.active_plan_name === "No Plan") {
                freePreviousMonth = freePreviousMonth + (freeRevenuePrice * previousPositive["count"]);
            }
            else if (previousPositive.active_plan_name === "Premium") {
                premiumPreviousMonth = premiumPreviousMonth + (premiumRevenuePrice * previousPositive["count"]);
            }
        });
        return {
            currentMonthRevenue: { totalprice: (basicCurrentMonth + freeCurrentMonth + premiumCurrentMonth).toFixed(2), currentMonthName: currentMonth },
            previousMonthRevenue: { totalprice: (basicPreviousMonth + freePreviousMonth + premiumPreviousMonth).toFixed(2), previousMonthName: previousMonth }
        }
    }
    const calculateWidgetRetention = retentionStats => {
        let currentMonthTotalRecords = 0;
        let currentMonthActiveRecords = 0;
        let previousMonthTotalRecords = 0;
        let previousMonthActiveRecords = 0;
        retentionStats.currentMonthRetention.forEach(retentionRecord => {
            currentMonthTotalRecords = currentMonthTotalRecords + retentionRecord["count(app_install_id)"];
            if (retentionRecord.status === "Active") {
                currentMonthActiveRecords = currentMonthActiveRecords + retentionRecord["count(app_install_id)"];
            }
        });
        retentionStats.previousMonthRetention.forEach(retentionRecord => {
            previousMonthTotalRecords = previousMonthTotalRecords + retentionRecord["count(app_install_id)"];
            if (retentionRecord.status === "Active") {
                previousMonthActiveRecords = previousMonthActiveRecords + retentionRecord["count(app_install_id)"];
            }
        });
        let currentMonthRetentionPercent = ((currentMonthActiveRecords / currentMonthTotalRecords) * 100).toFixed(2);
        let previousMonthRetentionPercent = ((previousMonthActiveRecords / previousMonthTotalRecords) * 100).toFixed(2);
        return {
            currentMonthRetentionPercent,
            currentMonthActiveRecords,
            currentMonthTotalRecords,
            previousMonthRetentionPercent,
            previousMonthActiveRecords,
            previousMonthTotalRecords
        };
    }
    const calculateTenure = tenure => {
        let date = new Date();
        let currentDate = date.getDate();
        let currentMonth = date.getMonth();
        let currentYear = date.getFullYear();
        let beginningDate = `${currentYear}-${currentMonth}-01`;
        let todayDate = `${currentYear}-${currentMonth}-${currentDate}`
        if (tenure === "week") {
            return {
                startdate: "",
                enddate: ""
            }
        }
        else if (tenure === "month") {
            return {
                startdate: beginningDate,
                enddate: todayDate
            }
        }
        else return {
            startdate: "",
            enddate: ""
        };
    }
    const calculateGraphData = data => {
        let totalactiveusers = 0;
        data.forEach(datakey => {
            totalactiveusers = totalactiveusers + datakey.count;
        });
        return data.map(datakey => {
            if (datakey.active_plan_name !== 'null' || datakey.active_plan_name !== 'No plan')

                return {
                    name: datakey.active_plan_name,
                    value: datakey.count,
                    percent: parseFloat(((datakey.count / totalactiveusers) * 100)).toFixed(2)
                }

            else {
                return;
            }
        });
    }
    const formatCountryData = data => {
        return data.map(dataitem => {
            return {
                install_count: dataitem.install_count,
                image: countryList.find(country => country.name === dataitem.country).code.toLocaleLowerCase(),
                country_name: dataitem.country,
                store_type: dataitem.store_type
            }
        });
    }
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length)
            return (
                <>
                    <div className='custom-tooltip bg-light p-1 rounded-2'>
                        <p className='m-0 p-0' style={{ color: "orangered", fontWeight: "bold" }}>Date: {payload[0].payload.label}</p>
                        <p className='p-0 m-0' style={{ color: "orange", fontWeight: "bold" }}>Count: {payload[0].payload.installcount}</p>
                    </div>
                </>
            )
        else return null;
    }
    //for the top header cards//
    useEffect(() => {
        setusers((users) => ({ ...users, loading: false }))
        axios.get(`/user/appinstalls`, {
            params: {
                status: showdropdownfilter.status,
                frequency: showdropdownfilter.frequency
            }
        }).then(response => {

            allActiveBarData(response.data.data.barlist);
            setMonthlyRevenue(calculateWidgetRevenue(response.data.data.positive, response.data.data.previousPositive, response.data.data.currentMonth, response.data.data.previousMonth));
            setWidgetRetention(calculateWidgetRetention(response.data.data.retentionStats));
            setAppWidget([{ label: "Active Installs", type: "Active", count: response.data.data.totalInstalls }, { label: "Revenue", type: "revenue", count: response.data.data.list.length }, { label: "Retention", count: response.data.data.list.length, type: "retention" }]);
        }).finally(response => ({ ...users, loading: false }));
    }, []);
    //end//

    //for the chart data //
    useEffect(() => {
        setusers((users) => {
            return {
                ...users, loading: true
            }
        })
        axios.get(`/user/appinstalls`, {
            params: {
                status: showdropdownfilter.status,
                frequency: showdropdownfilter.frequency
            }
        }).then(response => {
            setusers((users) => ({ ...users, loading: false }))
            let data;
            if (showdropdownfilter.frequency === "all")
                setChartTableData({
                    ...charttabledata, tbody: calculateGraphData(response.data.data.piechartdata)
                });
            else if (showdropdownfilter.frequency === "week") {
                data = response.data.data.piechartDataWeek;
                setChartTableData({ ...charttabledata, tbody: calculateGraphData(data) });
            }
            else if (showdropdownfilter.frequency === "month") {
                data = response.data.data.piechartDataMonth;
                setChartTableData({ ...charttabledata, tbody: calculateGraphData(data) });
            }
            else if (showdropdownfilter.frequency === "thisweek") {
                data = response.data.data.piechartdatathisweek;
                setChartTableData({ ...charttabledata, tbody: calculateGraphData(data) })
            }
            else if (showdropdownfilter.frequency === "thismonth") {
                data = response.data.data.piechartdatathismonth;
                setChartTableData({ ...charttabledata, tbody: calculateGraphData(data) })
            }
        }).finally(response => setusers((users) => ({ ...users, loading: false })));
    }, [showdropdownfilter.frequency]);
    //end//

    //for the countrywiseinstalls//
    useEffect(() => {
        axios("/user/appinstalls", {
            method: "GET",
            params: {
                status: showdropdownfilter.status,
                frequency: showdropdownfilter.frequency
            }
        }).then(response => {
            if (countryWiseInstalls.frequency === "all")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countryWiseInstalls) } });
            else if (countryWiseInstalls.frequency === "week")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countrywiseInstallsLastWeek) } });
            else if (countryWiseInstalls.frequency === "month")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countrywiseInstallsLastMonth) } });
            else if (countryWiseInstalls.frequency === "thisweek")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countrywiseInstallsThisWeek) } });
            else if (countryWiseInstalls.frequency === "thismonth")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countrywiseInstallsThisMonth) } });
        })
    }, [countryWiseInstalls.frequency]);
    //end//

    //for the table data//
    useEffect(() => {
        let dates = calculateTenure(showdropdownfilter.frequency);
        let payload = {
            startdate: dates.startdate,
            enddate: dates.enddate,
            status: showdropdownfilter.status,
            frequency: showdropdownfilter.frequency
        }
        setusers((users) => ({ ...users, loading: true }));
        axios.get(`/user/appinstalls`,
            {
                params: {
                    startdate: payload.startdate,
                    enddate: payload.enddate,
                    status: payload.status,
                    frequency: payload.frequency
                }
            }).then(response => {
                setusers({ ...users, allInstalls: response.data.data.totalInstalls, tabledata: { ...users.tabledata, tbody: formatdata(response.data.data.list) }, loading: false });
            }).finally(response => setusers((users) => ({ ...users, loading: false })));
    }, [showdropdownfilter.status]);
    // //for the table data end//


    return (
        <>{users.loading ?
            //  <CustomBackDrop > 
            <Backdrop open>
                <Loading />
                {/* </CustomBackDrop>  */}
            </Backdrop>
            : <div className='h-100'>
                <MetaTags title={"Widgets"} />
                <div className='p-4 pb-0 heading-content bg-light'>
                    <h4 className="m-0">Widgets</h4>
                </div>
                <div className='container widget-container bg-light p-4'>
                    <Row className='bg-light d-flex justify-content-between mb-3'>
                        {appwidget.map((key, index) => <Col className='bg-light border-muted' key={index} xs={12} md={4}>
                            <div className='d-flex gap-4 h-100 rounded-4 p-4 bg-white'>
                                <div className='gap-2 d-flex'>
                                    <div className='py-3'>
                                        <span className='rate-icon' style={{ backgroundColor: "#f5f5fe", padding: 19, borderRadius: "11px" }}>
                                            {key.type === "Active" &&
                                                <RiInstallFill color={"blue"} size={"25px"} />
                                            }
                                            {key.type === "revenue" && <AiOutlineUserDelete color='blue' size={"25px"} />}
                                            {key.type === "retention" && <RiInstallFill color={"blue"} size={"25px"} />}
                                        </span>
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <div className=''>
                                            {key.type === "revenue" &&
                                                <div className='d-flex align-items-center'>
                                                    <h2>${monthlyRevenue.currentMonthRevenue.totalprice}</h2>
                                                </div>
                                            }
                                            {key.type === "retention" &&
                                                <div className='content-overview d-flex align-items-center gap-2'>
                                                    <h2>{widgetretention.currentMonthRetentionPercent}%</h2>
                                                    <p className='status-percentage'>
                                                        {widgetretention.currentMonthActiveRecords} out of {widgetretention.currentMonthTotalRecords}
                                                    </p>
                                                </div>
                                            }
                                            {
                                                key.type === "Active" &&
                                                <div className='d-flex flex-column align-items-start content-overview'>
                                                    <h2>{key.count}</h2>
                                                    <p style={{ color: "#f5aa33" }}>
                                                        {key.label}
                                                    </p>
                                                    <p className='status-percentage'>Since last Week</p>
                                                </div>
                                            }

                                        </div>
                                        {
                                            key.type === "revenue" &&
                                            <div className='d-flex align-items-center gap-2 content-overview'>
                                                <p className=''>
                                                    {key.label}
                                                </p> -
                                                <p style={{ color: "#f5aa33" }} className='mb-0'>
                                                    {monthlyRevenue.currentMonthRevenue.currentMonthName}
                                                </p>
                                            </div>
                                        }
                                        <div className='d-flex align-items-center gap-1 content-overview'>
                                            {
                                                key.type === "revenue" &&
                                                <>
                                                    {(monthlyRevenue.currentMonthRevenue.totalprice > monthlyRevenue.previousMonthRevenue.totalprice) ? <HiArrowNarrowUp size={21} className='text-success' /> :
                                                        <HiArrowNarrowDown size={21} color='text-danger' />
                                                    }
                                                    <p className='status-percentage' style={{ color: monthlyRevenue.currentMonthRevenue.totalprice > monthlyRevenue.previousMonthRevenue.totalprice ? "green" : "red" }}>
                                                        {(monthlyRevenue.currentMonthRevenue.totalprice > monthlyRevenue.previousMonthRevenue.totalprice) ? (((monthlyRevenue.currentMonthRevenue.totalprice - monthlyRevenue.previousMonthRevenue.totalprice) / monthlyRevenue.currentMonthRevenue.totalprice) * 100).toFixed(2) : (((monthlyRevenue.previousMonthRevenue.totalprice - monthlyRevenue.currentMonthRevenue.totalprice) / monthlyRevenue.previousMonthRevenue.totalprice) * 100).toFixed(2)}
                                                        %
                                                    </p>
                                                    <p className='status-percentage'>Since Last Month</p>
                                                    <p className='status-percentage' style={{ color: "#f5aa33" }}>
                                                        {monthlyRevenue.previousMonthRevenue.previousMonthName}
                                                    </p>
                                                    <p className='status-percentage'>${monthlyRevenue.previousMonthRevenue.totalprice}</p>
                                                </>
                                            }
                                            {
                                                key.type === "retention" && <div className='content-overview'>
                                                    <div className='d-flex align-items-center gap-2 content-overview'>
                                                        <p style={{ color: "#f5aa33" }} className='status-percentage'>
                                                            {key.label}
                                                        </p> -
                                                        <p className='status-percentage'>
                                                            {monthlyRevenue.currentMonthRevenue.currentMonthName}
                                                        </p>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <p className='status-percentage'>
                                                            Since last month
                                                        </p>
                                                        <p style={{ color: "#f5aa33" }} className='status-percentage'>
                                                            {monthlyRevenue.previousMonthRevenue.previousMonthName}
                                                        </p>
                                                        <p className='status-percentage'>
                                                            {widgetretention.previousMonthRetentionPercent}%
                                                        </p>
                                                        <p className='status-percentage'>{widgetretention.previousMonthActiveRecords} out of {widgetretention.previousMonthTotalRecords}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='bar-container'>
                                    {
                                        key.type === "Active" &&
                                        <BarChart data={barData} width={150} height={150}>
                                            <Bar dataKey={"installcount"} fill={"#f5aa33"} />
                                            <Tooltip content={<CustomTooltip />} />
                                            <YAxis key={"installcount"} />
                                            <XAxis key={"label"} />
                                        </BarChart>
                                    }
                                </div>
                            </div>
                        </Col>)}
                    </Row>
                    <Row className='grid-box-row p-0 mt-3'>
                        <Col xs={7} className=''>
                            <div className='grid-box h-100 m-0'>
                                <div className='grid-box-heading d-flex justify-content-between align-items-center'>
                                    <div className=''>
                                        <h3 style={{ color: "#f5aa33" }}>
                                            Store Listing
                                        </h3>
                                    </div>
                                    <div className="boxsize boxsize-transparent p-2">
                                        <button className={showdropdownfilter.status === 'all' ? "active fs-5" : "fs-5"} onClick={setAll}>All</button>
                                        <button className={showdropdownfilter.status === 'Active' ? "active fs-5" : "fs-5"} onClick={setActive}>Active</button>
                                        <button className={showdropdownfilter.status === 'inActive' ? "active fs-5" : "fs-5"} onClick={setInActive}>InActive</button>
                                    </div>
                                </div>
                                <div className='table-responsive'>
                                    <Tablelist tabledata={users.tabledata} loading={users.loading} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={5} className=''>
                            <div className='grid-box m-0 h-50'>
                                <div className='grid-box-heading mb-0'>
                                    <div>
                                        <h3 style={{ color: "#f5aa33" }}>
                                            Plans Count
                                        </h3>
                                    </div>
                                    <div className='dropdown'>
                                        <button style={{ color: "#f5aa33" }} className='dropdown-toggle p-2 rounded-2 fs-5' id='dropdownaction' aria-haspopup="tree" data-bs-toggle="dropdown" aria-expanded="false">
                                            {showdropdownfilter.frequency === "week" && <>Last 7 DAYS</>}
                                            {showdropdownfilter.frequency === "month" && <>Last 30 DAYS</>}
                                            {showdropdownfilter.frequency === "thisweek" && <>This Week</>}
                                            {showdropdownfilter.frequency === "thismonth" && <>This Month</>}
                                            {showdropdownfilter.frequency === "all" && <>All Time</>}
                                        </button>
                                        <div className="dropdown-menu boxsize boxsize-transparent p-2" aria-labelledby='dropdownaction'>
                                            <button className={showdropdownfilter.frequency === 'all' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("all")}>All Time</button>
                                            <hr className='dropdown-divider' />
                                            <button className={showdropdownfilter.frequency === 'week' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("week")}>Last 7 Days</button>
                                            <hr className='dropdown-divider' />
                                            <button className={showdropdownfilter.frequency === 'month' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("month")}>Last 30 DAYS</button>
                                            <hr className='dropdown-divider' />
                                            <button className={showdropdownfilter.frequency === 'thisweek' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("thisweek")}>This Week</button>
                                            <hr className='dropdown-divider' />
                                            <button className={showdropdownfilter.frequency === 'thismonth' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("thismonth")}>This Month</button>
                                        </div>
                                    </div>
                                </div>
                                <PieCharts data={charttabledata.tbody} data992={1.5} irr={75} orr={100} DataKey={"value"} />
                            </div>
                            <div className="grid-box m-0 h-50 p-2">
                                <Tablelist tabledata={charttabledata} loading={users.loading} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='grid-box-row p-0 mt-3'>
                        <Col xs={6} className=''>
                            <div className='grid-box m-0'>
                                <div className='grid-box-heading'>
                                    <div className=''>
                                        <h3 style={{ color: "#f5aa33" }}>
                                            Country Wise Installs
                                        </h3>
                                    </div>
                                    <div className='dropdown'>
                                        <button style={{ color: "#f5aa33" }} className='dropdown-toggle p-2 rounded-2 fs-5' id="dropdownexecute" aria-haspopup="true" aria-expanded="false" data-bs-toggle="dropdown">
                                            {countryWiseInstalls.frequency === "all" && <>All Time</>}
                                            {countryWiseInstalls.frequency === "week" && <>Last 7 DAYS</>}
                                            {countryWiseInstalls.frequency === "month" && <>Last 30 DAYS</>}
                                            {countryWiseInstalls.frequency === "thisweek" && <>This Week</>}
                                            {
                                                countryWiseInstalls.frequency === "thismonth" && <>This Month</>
                                            }
                                        </button>
                                        <div className='dropdown-menu boxsize boxsize-transparent p-2' aria-labelledby='dropdownexecute'>
                                            <button className={countryWiseInstalls.frequency === "all" ? 'dropdown-item fs-6 text-start active' : 'dropdown-item fs-6 text-start'} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "all" })}>
                                                All Time
                                            </button>
                                            <hr className='dropdown-divider' />
                                            <button className={countryWiseInstalls.frequency === "thisweek" ? "dropdown-item fs-6 active text-start" : "dropdown-item fs-6 text-start"} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "thisweek" })}>
                                                This Week
                                            </button>
                                            <hr className='dropdown-divider' />
                                            <button className={countryWiseInstalls.frequency === "thismonth" ? "dropdown-item fs-6 active text-start" : "dropdown-item fs-6 text-start"} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "thismonth" })}>
                                                This Month
                                            </button>
                                            <hr className='dropdown-divider' />
                                            <button className={countryWiseInstalls.frequency === "week" ? "dropdown-item fs-6 active text-start" : "dropdown-item fs-6 text-start"} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "week" })}>
                                                Last 7 Days
                                            </button>
                                            <hr className='dropdown-divider' />
                                            <button className={countryWiseInstalls.frequency === "month" ? "dropdown-item fs-6 active text-start" : "dropdown-item fs-6 text-start"} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "month" })}>
                                                Last 30 Days
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='table-responsive'>
                                    <Tablelist tabledata={countryWiseInstalls.tabledata} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className='grid-box'>
                                <p>Pie Chart for the country legends</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>}

        </>
    )
}
export default Widgets;

//this for the custom backdrop//
const CustomBackDrop = (props) => {
    return (
        <>
            <div className='backdrop-container'>
                {/* inside the backdrop container */}
                {props.children}
            </div>
        </>
    )
}
//end//