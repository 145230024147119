// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  color: #f5aa33;
  text-transform: uppercase;
  font-weight: 500;
  font-size: large;
}

.grid-box .grid-box-heading .analytics-page .stats-label {
  position: relative;
}
.grid-box .grid-box-heading .analytics-page .stats-label .date-calender {
  z-index: 10;
  top: 20px;
}
.grid-box .grid-box-heading h4,
.grid-box .grid-box-heading h3 {
  color: #f5aa33;
}
.grid-box .grid-box-heading div.property-row {
  padding: 17px;
  outline: 2px solid rgb(241, 238, 238);
}
.grid-box .grid-box-heading div.property-row:hover {
  background-color: #f8f9fb;
  cursor: pointer;
}

.store-basic-details .header-label {
  border-bottom: transparent;
}
.store-basic-details .header-label .page-label {
  color: #f5aa33;
}
.store-basic-details .detail-section-container {
  border-bottom: transparent;
}
.store-basic-details .detail-section-container span.side-labels {
  color: #f5aa33;
  font-size: large;
}`, "",{"version":3,"sources":["webpack://./src/Components/reports/storedetail/index.scss"],"names":[],"mappings":"AAEA;EACI,cAHI;EAIJ,yBAAA;EACA,gBAAA;EACA,gBAAA;AADJ;;AAQY;EACI,kBAAA;AALhB;AAOgB;EACI,WAAA;EACA,SAAA;AALpB;AAUQ;;EAEI,cAzBJ;AAiBR;AAWQ;EACI,aAAA;EACA,qCAAA;AATZ;AAYQ;EACI,yBAAA;EACA,eAAA;AAVZ;;AAgBI;EACI,0BAAA;AAbR;AAeQ;EACI,cA7CJ;AAgCR;AAkBI;EACI,0BAAA;AAhBR;AAkBQ;EACI,cAtDJ;EAuDI,gBAAA;AAhBZ","sourcesContent":["$color: #f5aa33;\r\n\r\nlabel {\r\n    color: $color;\r\n    text-transform: uppercase;\r\n    font-weight: 500;\r\n    font-size: large;\r\n}\r\n\r\n.grid-box {\r\n\r\n    .grid-box-heading {\r\n        .analytics-page {\r\n            .stats-label {\r\n                position: relative;\r\n\r\n                .date-calender {\r\n                    z-index: 10;\r\n                    top: 20px;\r\n                }\r\n            }\r\n        }\r\n\r\n        h4,\r\n        h3 {\r\n            color: $color;\r\n        }\r\n\r\n        div.property-row {\r\n            padding: 17px;\r\n            outline: 2px solid rgb(241, 238, 238);\r\n        }\r\n\r\n        div.property-row:hover {\r\n            background-color: #f8f9fb;\r\n            cursor: pointer\r\n        }\r\n    }\r\n}\r\n\r\n.store-basic-details {\r\n    .header-label {\r\n        border-bottom: transparent;\r\n\r\n        .page-label {\r\n            color: $color;\r\n        }\r\n\r\n    }\r\n\r\n    .detail-section-container {\r\n        border-bottom: transparent;\r\n\r\n        span.side-labels {\r\n            color: $color;\r\n            font-size: large;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
